import React, { useState } from "react";
import { DLTablePage } from "./DLTablePage";
import StepInstructions from "./StepInstructions";


import { Button, Dropdown, Modal } from 'react-bootstrap';

const DropdownToggle = React.forwardRef(({ children, onClick }, ref) => (
    <button
        className="btn btn-link"
        href="#"
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </button>
  ));

/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
export const PageLayout = (props) => {


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
        <div className="col-lg-8 mx-auto p-3 py-md-5">
            <header className="py-3 mb-3 border-bottom">
                <div className="container-fluid d-grid gap-3 align-items-center">
                    <div className="d-flex align-items-center">
                        <div class="row">
                            <div class="col col-2">
                                <img src="logo.png" class="img-fluid" alt="Tietoevry logotype" />
                            </div>
                            <div class="col">
                                <div class="row">
                                    <div class="col">
                                    <a href="/" className="d-flex align-items-center text-dark text-decoration-none w-100 me-3">
                                        <span className="fs-4">SNMO Distributionslistor</span>
                                    </a>
                                    </div>
                                    <div className="col col-1 flex-shrink-0 dropdown">
                                        <Dropdown>
                                            <Dropdown.Toggle as={DropdownToggle} id="dropdown-basic">
                                                <i class="bi bi-list" style={{fontSize: 20, color: '#6c757d'}}></i>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={handleShow}>Hjälp</Dropdown.Item>
                                                <Dropdown.Divider />
                                                <Dropdown.Item >Logga ut</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        
                    </div>
                </div>
                
            </header>
            <StepInstructions />
            <DLTablePage />
            
            {props.children}
            <footer class="pt-5 my-5 text-muted border-top">
                Gabriel Gunnarsson, Tietoevry &middot; &copy; 2022 &middot; <span role="button" className="link-secondary" onClick={handleShow}><u>Hjälp</u></span>
            </footer>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                <Modal.Title>Hjälp</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Vad är syftet?</h5>
                    <p>
                        Idag finns ett stort antal distributionslistor i SNMOs miljö. För att förenkla kommande åtgärder som utförs i syfte att höja IT-säkerheten går vi nu igenom 
                        alla listor och uppmanar ägarna att berätta för oss om distributionslistan fortfarande behövs.
                    </p>
                    <h5>Hur gör jag?</h5>
                    <p>
                        På sidan ser du en lista med alla distributionslistor som du är ägare för. Gå igenom listan och välj att antingen behålla eller radera listan. När svarsperioden är slut kommer svaren gås igenom och berörda listor tas bort.
                        Genom att trycka på <i>Info</i> kan du se mer information om distributionslistan. Observera att en lista kan ha flera ägare.
                    </p>
                    <h5>Jag har upptäckt medlemmar som inte längre ska vara kvar i en lista</h5>
                    <p>
                        Kontakta Servicedesk via email <a href="mailto:servicedesk.snmo@evry.com">servicedesk.snmo@evry.com</a> för att beställa ändringar i distributionslistan. 
                    </p>
                    <h5>Hur gör jag för att ändra åtgärd på en distributionslista?</h5>
                    <p>
                        Du kan ändra en redan markerad åtgärd genom att trycka på <i>Info</i> och sedan <i>Ändra åtgärd</i>.
                    </p>
                    <h5>En lista som redan tagits bort finns i listan!</h5>
                    <p>
                        Informationen i denna lista synkroniseras inte med Outlook i realtid, och ändringar kan därför dröja upp till 3 dygn.
                    </p>
                    <h5>Kontakta servicedesk</h5>
                    <p>
                        Kontakta Servicedesk via 08-7626750 (välj 2 för IT-support) eller servicedesk.snmo@lexicon.se.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Stäng
                </Button>
                </Modal.Footer>
            </Modal>
        </div>
        </>
    );
};