import React from 'react';

export default function DLTableColoredTableMobile(props) {

    if(props.shouldBeKept === undefined) {
        return <table class="table table-striped">{props.children}</table>
    }

    if(props.shouldBeKept === true) {
        return <table className="table table-striped border-success">{props.children}</table>
    } else {
        return <table className="table table-striped border-danger">{props.children}</table>
    }
}
