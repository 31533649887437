import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function DLInfoTooltip(props) {

    return <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="button-tooltip-2">{props.explanation}</Tooltip>}
    >
        {({ ref, ...triggerHandler }) => (
        <span
        {...triggerHandler}>
            <strong>{props.title}  </strong>
            <i
            class="bi bi-info-circle"
            ref={ref}
            />
        </span>
        )}
    </OverlayTrigger>;
}

