import React from 'react';
import DLTableTr from './DLTableTr'
import DLTableColoredTrMobile from './DLTableColoredTrMobile';
import DLTableColoredTableMobile from './DLTableColoredTableMobile';
import DLTableStatusText from './DLTableStatusText'
import { ProgressBar, Form } from 'react-bootstrap';

import DLActionButtons from './DLActionButtons';

export default class DLTable extends React.Component {

    constructor(props) {
        super(props);

        const dlReviewCountValue = this.props.dls.filter(obj => {
            if(obj.reviewresults && obj.addedDueToMOmatch === undefined) {
                return true;
            }
            return false;
        }).length;

        const dlMaxCountValue = this.props.dls.filter(obj => {
            if(obj.addedDueToMOmatch === undefined) {
                return true;
            }
            return false;
        }).length;

        this.state = { selectedMo: "default", progressNow: dlReviewCountValue, progressMax: dlMaxCountValue }
        this.changeSelect = this.changeSelect.bind(this);
        
    }

    changeSelect(e) {
        let dlReviewCountValue = 0;
        let dlMaxCountValue = 0;

        if (e.target.value.includes('noOwner')) {
            console.log("noOwner")
            dlReviewCountValue = this.props.dls.filter(obj => {
                if(obj.reviewresults && obj.MO + "noOwner" === e.target.value && obj.managers?.length === 0) {
                    return true;
                }
                return false;
            }).length;

            dlMaxCountValue = this.props.dls.filter(obj => {
                if(obj.MO + "noOwner" === e.target.value && obj.managers?.length === 0) {
                    return true;
                }
                return false;
            }).length;
        } else if(e.target.value !== "default") {
            dlReviewCountValue = this.props.dls.filter(obj => {
                if(obj.reviewresults && obj.MO === e.target.value) {
                    return true;
                }
                return false;
            }).length;

            dlMaxCountValue = this.props.dls.filter(obj => {
                if(obj.MO === e.target.value) {
                    return true;
                }
                return false;
            }).length;
        } else {
            dlReviewCountValue = this.props.dls.filter(obj => {
                if(obj.reviewresults && obj.addedDueToMOmatch === undefined) {
                    return true;
                }
                return false;
            }).length;

            dlMaxCountValue = this.props.dls.filter(obj => {
                if(obj.addedDueToMOmatch === undefined) {
                    return true;
                }
                return false;
            }).length;
        }
        

        this.setState({selectedMo: e.target.value, progressNow: dlReviewCountValue, progressMax: dlMaxCountValue});

    }

    render() {
        const { selectedMo, progressNow, progressMax } = this.state;
        const { changeSelect } = this;

        return (
            <>
            <div class="pb-3">
                <ProgressBar now={progressNow} max={progressMax} label={progressNow + "/" + progressMax}/>
            </div>
            <div class="d-none d-lg-block">
                {this.props.userMOmapping.length >= 1 && 
                    <Form.Select value={selectedMo} onChange={changeSelect}>
                        <>
                        <option value="default">Visa endast mina distributionslistor</option>
                        {this.props.userMOmapping.map((value) => {
                            // <option value={value.MO}>{value.MO} (Alla)</option>
                            return (
                                <>
                                    
                                    <option value={value.MO + "noOwner"}>{value.MO} (Utan ägare)</option>
                                </>
                            );  
                        })}
                        </>
                    </Form.Select>
                }
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Namn</th>
                            <th scope="col">Primär Mailadress</th>
                            <th scope="col">Antal medlemmar</th>
                            <th scope="col">Status</th>
                            <th scope="col">Åtgärder</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/*eslint-disable-next-line array-callback-return*/}
                        {this.props.dls.map((value, index) => {
                            if(selectedMo === "default" && value.addedDueToMOmatch === undefined) {
                                return (
                                    <DLTableTr shouldBeKept={value.reviewresults?.shouldBeKept} key={value.WindowsEmailAddress + "desktop"} >
                                        <td>{value.DisplayName}</td>
                                        <td>{value.PrimarySmtpAddress}</td>
                                        <td>{value.members.length === 0 && <i class="bi bi-exclamation-triangle-fill text-warning"></i>}{value.members.length}</td>
                                        <td><DLTableStatusText loading={value.loading} error={value.error} reviewResults={value.reviewresults} /></td>
                                        <td><DLActionButtons dl={value} updateSpecificDLCallback={this.props.updateSpecificDLCallback} markDLasLoadingCallback={this.props.markDLasLoadingCallback} /></td>
                                    </DLTableTr>
                                );
                            } else if (selectedMo === value.MO) {
                                return (
                                    <DLTableTr shouldBeKept={value.reviewresults?.shouldBeKept} key={value.WindowsEmailAddress + "desktop"} >
                                        <td>{value.DisplayName}</td>
                                        <td>{value.PrimarySmtpAddress}</td>
                                        <td>{value.members.length === 0 && <i class="bi bi-exclamation-triangle-fill text-warning"></i>}{value.members.length}</td>
                                        <td><DLTableStatusText loading={value.loading} error={value.error} reviewResults={value.reviewresults} /></td>
                                        <td><DLActionButtons dl={value} updateSpecificDLCallback={this.props.updateSpecificDLCallback} markDLasLoadingCallback={this.props.markDLasLoadingCallback} /></td>
                                    </DLTableTr>
                                );
                            } else if (selectedMo === value.MO + "noOwner") {
                                if(value.managers?.length === 0) {
                                    return (
                                        <DLTableTr shouldBeKept={value.reviewresults?.shouldBeKept} key={value.WindowsEmailAddress + "desktop"} >
                                            <td>{value.DisplayName}</td>
                                            <td>{value.PrimarySmtpAddress}</td>
                                            <td>{value.members.length === 0 && <i class="bi bi-exclamation-triangle-fill text-warning"></i> } {value.members.length}</td>
                                            <td><DLTableStatusText loading={value.loading} error={value.error} reviewResults={value.reviewresults} /></td>
                                            <td><DLActionButtons dl={value} updateSpecificDLCallback={this.props.updateSpecificDLCallback} markDLasLoadingCallback={this.props.markDLasLoadingCallback} /></td>
                                        </DLTableTr>
                                    );
                                }
                            }
                        })}
                    </tbody>
                </table>
            </div>
            <div class="d-lg-none">
                
                {this.props.dls.map((value, index) => {
                    return (
                        <>
                        <DLTableColoredTableMobile shouldBeKept={value.reviewresults?.shouldBeKept} key={value.WindowsEmailAddress + "tablemobile"}>
                            <tbody>
                                <DLTableColoredTrMobile shouldBeKept={value.reviewresults?.shouldBeKept} key={value.WindowsEmailAddress + "mobile"}>
                                    <td><strong>Namn</strong></td>
                                    <td class="text-break"><strong>{value.DisplayName}</strong></td>
                                </DLTableColoredTrMobile>
                                <tr>
                                    <td>Mail</td>
                                    <td class="text-break">{value.PrimarySmtpAddress}</td>
                                </tr>
                                <tr>
                                    <td>Medlemmar</td>
                                    <td class="text-break">{value.members.length === 0 && <i class="bi bi-exclamation-triangle-fill text-warning"></i>}{value.members.length}</td>
                                </tr>
                                <tr>
                                    <td>Status</td>
                                    <td class="text-break"><DLTableStatusText loading={value.loading} error={value.error} reviewResults={value.reviewresults} /></td>
                                </tr>
                                <tr>
                                    <td colSpan="2"><div class="text-center"><DLActionButtons dl={value} updateSpecificDLCallback={this.props.updateSpecificDLCallback} markDLasLoadingCallback={this.props.markDLasLoadingCallback} /></div></td>
                                </tr>
                                </tbody>
                            </DLTableColoredTableMobile>
                        </>
                    );
                })}
                
            </div>
            </>
        );
    }
}