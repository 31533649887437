import React from 'react';
import InlineLoading from '../InlineLoading';


export default function DLTableTr(props) {

    if(props.error) {
        return <><i class="bi bi-exclamation-triangle-fill text-danger"></i> Försök igen</>
    }

    if(props.loading) {
        return <InlineLoading />
    }

    if(props.managers?.length === 0) {
        return <>Saknar ägare</>
    }

    if(props.reviewResults?.shouldBeKept === undefined) {
        return <>Inväntar svar</>
    }

    if(props.reviewResults?.shouldBeKept === true) {
        return <>Behåll</>;
    } else {
        return <>Ta bort </>
    }
}
