import React, { useState } from 'react';
import { Button, Modal, Accordion, Alert } from 'react-bootstrap';
import DLInfoTooltip from './DLInfoTooltip';
import DLLiTooltip from './DLLiTooltip';

import { protectedResources } from "../../authConfig";

import { postApiWithToken } from "../../fetch";
import { useAccount, useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-browser'

export function DLInfoModal (props) {

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [show, setShow] = useState(false);
    const [showConfirmChange, setShowConfirmChange] = useState(false);


    function handleClose() {
        setShow(false);
    }

    function handleShow() {
        setShow(true);
    }

    function handleCloseConfirmChange() {
        setShow(true);
        setShowConfirmChange(false);
    }

    function handleShowConfirmChange() {
        setShow(false);
        setShowConfirmChange(true);
    }

    function handleUpdateDL() {
        setShowConfirmChange(false);
        props.updateSpecificDLCallback(props.dl.PrimarySmtpAddress);
    }

    const handleConfirmChangeAction = () => {
        props.markDLasLoadingCallback(props.dl.PrimarySmtpAddress);
        setShow(false);
        setShowConfirmChange(false);

        instance.acquireTokenSilent({
            scopes: protectedResources.changeDLAction.scopes,
            account: account
        }).then((response) => {
            postApiWithToken(response.accessToken, protectedResources.changeDLAction.endpoint, {
                "dl_PrimarySmtpAddress": props.dl.PrimarySmtpAddress, 
                "user_PrimarySmtpAddress": response.account.username,
                "user_DisplayName": response.account.name})
            .then(() => {
                props.updateSpecificDLCallback(props.dl.PrimarySmtpAddress);
                    
            });
        }).catch(async (e) => {
            if (e instanceof InteractionRequiredAuthError) {
              await instance.acquireTokenPopup({
                scopes: protectedResources.changeDLAction.scopes,
                account: account
              });
    
              props.markDLasLoadingCallback(props.dl.PrimarySmtpAddress, true)
              
            }
    
            throw e;
        })
    }


    return (
        <>
            <Button variant="info" onClick={handleShow} size="sm">
            Info
            </Button>
    
            <Modal show={show && showConfirmChange === false} onHide={handleClose} size="xl">
            <div class="modal-header">
                <div class="modal-title h4">Mer info</div>
                <div>
                {/*<i class="bi bi-arrow-clockwise"></i>*/}
                    <button type="button" class="btn-close" aria-label="Close"></button>
                    
                </div>
            </div>
            <Modal.Body>
                <div class="row">
                    <span role="button" className="link-secondary text-end" onClick={handleUpdateDL}><u>Uppdatera</u></span>
                </div>
                <div class="row">
                    {props.dl.reviewresults?.shouldBeKept === true &&
                        <Alert key={props.dl.reviewresults?.RowKey} variant='success'>
                            <Alert.Heading>Markerad för att behållas!</Alert.Heading>
                            <div>
                                Denna distributionslista har markerats som aktiv och kommer att behållas.<hr/>
                                <strong>Namn:</strong> {props.dl.reviewresults.displayName} ({props.dl.reviewresults.primarySmtpAddress})<br/>
                                <strong>Datum/Tid:</strong> {new Date(props.dl.reviewresults.timestamp).toLocaleString('sv-SE')}
                            </div>
                        </Alert>
                    }
                    {props.dl.reviewresults?.shouldBeKept === false &&
                        <Alert key={props.dl.reviewresults?.RowKey} variant='danger'>
                            <Alert.Heading>Markerad för att radering!</Alert.Heading>
                            <div>
                                Denna distributionslista kommer att raderas.<hr/>
                                <strong>Namn:</strong> {props.dl.reviewresults.displayName} ({props.dl.reviewresults.primarySmtpAddress})<br/>
                                <strong>Datum/Tid:</strong> {new Date(props.dl.reviewresults.timestamp).toLocaleString('sv-SE')}
                            </div>
                        </Alert>
                    }
                    <div class="col">
                        <strong>Namn:</strong><br /> {props.dl.DisplayName}<br />
                        <DLInfoTooltip title="Grupptyp:" explanation="Om grupptypen också är en säkerhetsgrupp kan den också användas för att ge åtkomst till t.ex filytor."/><br /> {props.dl.GroupType === "Universal" ? 'Universell' : 'Universell, Säkerhetsgrupp'}<br />
                        <DLInfoTooltip title="Öppen för nya medlemmar:" explanation="Om gruppen är öppen kan alla interna användare gå med i gruppen via Outlook. Om godkännande krävs kan användare ansöka om medlemskap."/><br /> {props.dl.MemberJoinRestriction === "Open" && 'Ja'} {props.dl.MemberJoinRestriction === "Closed" && 'Nej'} {props.dl.MemberJoinRestriction === "ApprovalRequired" && 'Godkännande krävs'} <br />
                        <DLInfoTooltip title="Öppen för externa avsändare:" explanation="Om gruppen är öppen kan den ta emot mail från alla avsändare. En stängd grupp tar endast emot mail från interna avsändare inom SNMO."/><br /> {props.dl.RequireSenderAuthenticationEnabled ? "Stängd" : "Öppen"} <br />
                        <strong>Ägare:</strong><br /> <ul>{props.dl.managers.map((value, index) => {
                                                        if(value.admin_primarySmtpAddress === undefined) {
                                                            return (
                                                                <li>{value.DisplayName}</li>
                                                            );
                                                        } else {
                                                            return (
                                                                <DLLiTooltip title={value.DisplayName} explanation={"Tillagd av behörig beställare: " + value.admin_primarySmtpAddress + "<br />Datum/tid: " + new Date(value.createdAt).toLocaleString('sv-SE')} />
                                                            );
                                                        }
                                                        
                                                    })}</ul><br />
                    </div>
                    <div class="col">
                        <strong>Primär mailadress:</strong><br /> {props.dl.PrimarySmtpAddress}<br />
                        <strong>Dold i adresslistan:</strong><br /> {props.dl.HiddenFromAddressListsEnabled ? 'Ja' : 'Nej'}<br />
                        <DLInfoTooltip title="Öppen för att lämna:" explanation="Om gruppen är öppen kan interna användare själva lämna."/><br /> {props.dl.MemberDepartRestriction === "Open" ? 'Ja' : 'Nej'}<br />
                        <strong>Medlemmar:</strong><br /> <ul>{props.dl.members.map((value, index) => {
                                                        return (
                                                            <li>{value.PrimarySmtpAddress}</li>
                                                        );
                                                    })}</ul><br />
                        <br />
                    </div>
                </div>

                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Avancerat</Accordion.Header>
                        <Accordion.Body>
                            <div class="row">
                                <div class="col">
                                    <strong>Objektid:</strong><br /> {props.dl.ExternalDirectoryObjectId}<br />
                                </div>
                                <div class="col">
                                    <strong>Recipient Type:</strong><br /> {props.dl.RecipientType}<br />
                                    <strong>Recipient Type Details:</strong><br /> {props.dl.RecipientTypeDetails}<br />
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Modal.Body>
            <Modal.Footer>
                {props.dl.reviewresults?.shouldBeKept !== undefined && 
                    <Button variant="warning" onClick={handleShowConfirmChange}>
                        Ändra åtgärd
                    </Button>
                }
                <Button variant="secondary" onClick={handleClose}>
                Stäng
                </Button>
                
            </Modal.Footer>
            </Modal>
            {props.dl.reviewresults?.shouldBeKept !== undefined && 
            <Modal show={showConfirmChange && show === false} onHide={handleCloseConfirmChange} size="xl">
                <Modal.Header closeButton>
                <Modal.Title>Bekräfta ändring</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="row">
                        <div class="col">
                            <p>Om den nuvarande åtgärden är inkorrekt kan du ändra genom att trycka på knappen nedan.</p>
                        </div>
                        <div class="col">
                            <strong>Namn:</strong><br /> {props.dl.DisplayName}<br />
                            <strong>Åtgärd:</strong><br />{props.dl.reviewresults?.shouldBeKept === false ? 'Radera' : 'Behåll'}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseConfirmChange}>
                    Stäng
                </Button>
                <Button variant={props.dl.reviewresults?.shouldBeKept === false ? 'success' : 'danger'} onClick={handleConfirmChangeAction}>
                    {props.dl.reviewresults?.shouldBeKept === false ? 'Behåll' : 'Radera'}
                </Button>
                </Modal.Footer>
            </Modal>
            }
        </>
    );
}