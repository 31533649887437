import { useEffect, useState, useContext, useCallback } from "react";

import { protectedResources } from "../authConfig";
import { callApiWithToken, queryApiWithToken } from "../fetch";

import { UserContext } from '../UserContext'

import DLTable from './DLTable/DLTable'
import Loading from './Loading'

export const DLTablePage = () => {
    const [DLdata, setDLdata] = useState(null);
    const [userMOmapping, setUserMOmapping] = useState(null);
    const [loadingDLdata, setLoadingDLdata] = useState(true);
    const userObject = useContext(UserContext);

    useEffect(() => {
        if(!DLdata && loadingDLdata) {
            setLoadingDLdata(true);

            console.log("Grabbing API data")

            callApiWithToken(userObject.accessToken, protectedResources.retrieveDLData.endpoint)
                .then(response => {
                    setDLdata(response.dlArray)
                    setUserMOmapping(response.userMOmapping)
                })
                .catch(error => {
                    console.log(error)
                });
        }
        
    }, [DLdata, loadingDLdata, userObject.accessToken]);

    const updateSpecificDLCallback = useCallback((parameter) => {
        let dlObject = DLdata.find(obj => {
            return obj.PrimarySmtpAddress.trim() === parameter
        })

        console.log("Update Specific DL Callback")
        
        dlObject.loading = true;

        queryApiWithToken(userObject.accessToken, protectedResources.retrieveOneDL.endpoint, {mail: dlObject.PrimarySmtpAddress})
            .then((response) => {
                const dl = response[0]
                if(dlObject.addedDueToMOmatch) {
                    dl.addedDueToMOmatch = true;
                }

                const newDlData = DLdata.map(item => 
                    item.PrimarySmtpAddress.trim() === parameter.trim() 
                    ? dl
                    : item)

                setDLdata(newDlData)
            }).catch(error => {
                console.log(error)
            });

    }, [DLdata, userObject.accessToken])

    const markDLasLoadingCallback = useCallback((parameter, error = false) => {
        
        if(error) {
            console.log("Setting error true for " + parameter.trim())

            const newDlData = DLdata.map(item => 
                item.PrimarySmtpAddress.trim() === parameter.trim() 
                ? {...item, error: true}
                : item)

            setDLdata(newDlData)
        } else {
            console.log("Setting loading true for " + parameter.trim())

            const newDlData = DLdata.map(item => 
                item.PrimarySmtpAddress.trim() === parameter.trim() 
                ? {...item, loading: true}
                : item)

            setDLdata(newDlData)
        }
        
    }, [DLdata])

    return (
        <>
            { DLdata ? <DLTable dls={DLdata} userMOmapping={userMOmapping} updateSpecificDLCallback={updateSpecificDLCallback} markDLasLoadingCallback={markDLasLoadingCallback} /> : <Loading /> }
        </>
    );
}