import React from 'react';

import { ButtonGroup } from 'react-bootstrap';

import { DLInfoModal } from './DLInfoModal';
import { DLKeepModal } from './DLKeepModal';
import { DLRemoveModal } from './DLRemoveModal';
import { DLSelectOwnerModal } from './DLSelectOwnerModal';

export default function DLActionButtons(props) {
    if(props.dl.loading && props.dl.error === false) {
        return "";
    }

    if(props.dl.managers?.length === 0) {
        return <ButtonGroup>
            <DLInfoModal dl={props.dl} updateSpecificDLCallback={props.updateSpecificDLCallback} markDLasLoadingCallback={props.markDLasLoadingCallback}/>
            <DLSelectOwnerModal dl={props.dl} updateSpecificDLCallback={props.updateSpecificDLCallback} markDLasLoadingCallback={props.markDLasLoadingCallback}/>
            <DLRemoveModal dl={props.dl} updateSpecificDLCallback={props.updateSpecificDLCallback} markDLasLoadingCallback={props.markDLasLoadingCallback} />
        </ButtonGroup>
    }

    return <ButtonGroup>
        <DLInfoModal dl={props.dl} updateSpecificDLCallback={props.updateSpecificDLCallback} markDLasLoadingCallback={props.markDLasLoadingCallback}/> 
            {props.dl.reviewresults?.shouldBeKept === undefined && 
                <>
                    <DLKeepModal dl={props.dl} updateSpecificDLCallback={props.updateSpecificDLCallback} markDLasLoadingCallback={props.markDLasLoadingCallback} />
                    <DLRemoveModal dl={props.dl} updateSpecificDLCallback={props.updateSpecificDLCallback} markDLasLoadingCallback={props.markDLasLoadingCallback} />
                </>}
        </ButtonGroup>
}
