import React from "react";
import { InteractionRequiredAuthError } from '@azure/msal-browser'


export const UnauthedPageLayout = (props) => {


    return (
        <>
        <div className="col-lg-8 mx-auto p-3 py-md-5">
            <header className="py-3 mb-3 border-bottom">
                <div className="container-fluid d-grid gap-3 align-items-center">
                    <div className="d-flex align-items-center">
                        <a href="/" className="d-flex align-items-center text-dark text-decoration-none w-100 me-3">
                            <span className="fs-4">SNMO Distributionslistor</span>
                        </a>
                    </div>
                </div>
                
            </header>
            
            <div class="container">
                <div class="row">
                    <p>Vänligen logga in för att fortsätta</p><br /><br /><br /><br />

                    {!(props.error instanceof InteractionRequiredAuthError) && 
                        <p>Ett oväntat fel uppstod. Vänligen försök igen genom att ladda om sidan.<br/><br/>Om felet kvarstår, kontakta Servicedesk.</p>
                    }
                </div>
            </div>
            
            {props.children}
            <footer class="pt-5 my-5 text-muted border-top">
                Gabriel Gunnarsson, Tietoevry &middot; &copy; 2022 
            </footer>

        </div>
        </>
    );
};