import React, { useState, useEffect } from "react";
import { loginRequest, protectedResources } from "../authConfig";
import { PageLayout } from "../components/PageLayout";
import { useMsalAuthentication, useMsal, useAccount } from "@azure/msal-react";
import { InteractionType, InteractionRequiredAuthError } from '@azure/msal-browser'

import { UserContext } from "../UserContext";
import { UnauthedPageLayout } from "../components/UnauthedPageLayout";



export function Main (props) {
    //const isAuthenticated = useIsAuthenticated();
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [userObject, setUserObject] = useState(null);
    

    const { login, error } = useMsalAuthentication(InteractionType.Silent, loginRequest);

    useEffect(() => {
        if(error && error instanceof InteractionRequiredAuthError){
            login(InteractionType.Redirect, loginRequest)
        }

        if (account && inProgress === "none" && !userObject) {
            instance.acquireTokenSilent({
                scopes: protectedResources.retrieveDLData.scopes,
                account: account
            }).then((response) => {
                setUserObject({'accessToken': response.accessToken, 'name': response.account.name, 'upn': response.account.username})
                console.log(response);
            });
        }

        
    },[error, userObject, account, inProgress, instance, login])


    if(userObject) {
        return (
        
            <UserContext.Provider value={userObject}>
                <PageLayout></PageLayout>
            </UserContext.Provider>
        );
    } else {
        return(
            <UnauthedPageLayout error={error} />
        )
    }
    
};