import React from "react";
import { Main } from './layouts/Main';

function App() {
  
  return (
    <Main></Main>
  );
}

export default App;