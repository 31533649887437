import React, { useState, useCallback, useContext } from "react";
import { protectedResources } from "../../authConfig";
import { Button, Modal } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import { useMsal, useAccount } from "@azure/msal-react";
import { InteractionRequiredAuthError } from '@azure/msal-browser'
import { queryApiWithToken, postApiWithToken } from "../../fetch";
import { debounce } from "lodash";
import { UserContext } from "../../UserContext";

export function DLSelectOwnerModal (props) {
    //const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [show, setShow] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);
    const userObject = useContext(UserContext);

    function toggleModal() {
        setShow(!show);
    }

    /*const debounceLoadUsers = _.debounce((searchTerm, callback) => {
        loadUsers(searchTerm)
        .then((result) => {console.log(result); callback(null, {options: result})})
        .catch((error) => callback(error, null));
        
    }, 500);*/

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceLoadUsers = useCallback(
        debounce((inputText, callback) => {
            loadUsers(inputText).then((options) => callback(options));
        }, 1000),
        []
    );

    const loadUsers = value => {
        console.log("Search value " + value)

        let returnValue = instance.acquireTokenSilent({
            scopes: ["User.Read.All"],
            account: account
        }).then((response) => {
            console.log(response);
            let paramString = "";
            if(value) {
                paramString = "$filter=userType eq 'member'&$select=displayName,userprincipalname&$search=\"displayName:" + value + "\""
            } else {
                paramString = "$filter=userType eq 'member'&$select=displayName,userprincipalname"
            }
            let resValue = queryApiWithToken(response.accessToken, protectedResources.graphGetUsers.endpoint, paramString, false)
                .then((response) => {
                    const resValue = response.value;
                    return resValue;
                }).catch(error => {
                    console.log(error)
                });
            return resValue;
        }).catch(async (e) => {
            // Catch interaction_required errors and call interactive method to resolve
            if (e instanceof InteractionRequiredAuthError) {
                await instance.acquireTokenPopup({
                    scopes: ["User.Read.All"],
                    account: account
                });
            }

            throw e;
        });

        return returnValue;
    }

    const handleSetOwner = () => {
        console.log(props);
        props.markDLasLoadingCallback(props.dl.PrimarySmtpAddress);
        setShow(false);

        postApiWithToken(userObject.accessToken, protectedResources.setOwner.endpoint, {
            "dl_PrimarySmtpAddress": props.dl.PrimarySmtpAddress,
            "dl_Rowkey": props.dl.rowKey,
            "admin_primarySmtpAddress": userObject.upn,
            "target_userPrincipalName": selectedValue.userPrincipalName,
            "target_displayName": selectedValue.displayName})
            .then(() => {
                props.updateSpecificDLCallback(props.dl.PrimarySmtpAddress);    
            });
    }

    const onChangeSelectedOption = (e) => {
        const selectedOption = e;
        setSelectedValue(selectedOption);
        console.log(selectedOption);
    };

    return (
        <>
            <Button variant="warning" onClick={toggleModal} size="sm">
                Välj ägare
            </Button>

            <Modal show={show} onHide={toggleModal} size="xl">
                <Modal.Header closeButton>
                  <Modal.Title>Välj ägare</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="row">
                        <div class="col">
                            <p>Denna distributionslista saknar ägare. Innan distributionslistan kan behållas måste du ange vem som ska bli ägare av listan. </p>
                            <AsyncSelect
                                cacheOptions
                                defaultOptions
                                loadOptions={debounceLoadUsers}
                                getOptionValue={(option) => option.userPrincipalName}
                                getOptionLabel={(option) => option.displayName + " (" + option.userPrincipalName + ")"}
                                loadingMessage={() => "Söker..."}
                                noOptionsMessage={() => "Inga resultat hittades..."}
                                placeholder={"Sök efter ny ägare"}
                                value={selectedValue}
                                onChange={onChangeSelectedOption}
                            />
                        </div>
                        <div class="col">
                            <strong>Namn: </strong>{props.dl.DisplayName}<br /><br />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={toggleModal}>
                    Stäng
                  </Button>
                  <Button variant="warning" onClick={handleSetOwner}>
                    Välj ägare
                  </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};