import React from 'react';

export default function DLTableTr(props) {

    if(props.shouldBeKept === undefined) {
        return <tr>{props.children}</tr>
    }

    if(props.shouldBeKept === true) {
        return <tr className="table-success">{props.children}</tr>
    } else {
        return <tr className="table-danger">{props.children}</tr>
    }
}
