import React, { useState } from "react";
import { Button, Modal } from 'react-bootstrap';

import { protectedResources } from "../../authConfig";

import { postApiWithToken } from "../../fetch";
import { useMsal, useAccount } from "@azure/msal-react";
import { InteractionRequiredAuthError } from '@azure/msal-browser'

export function DLRemoveModal (props) {

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [show, setShow] = useState(false);

    function toggleModal() {
      setShow(!show);
    }

    const handleRemove = () => {
      props.markDLasLoadingCallback(props.dl.PrimarySmtpAddress);
      setShow(false);

      instance.acquireTokenSilent({
        scopes: protectedResources.removeDL.scopes,
        account: account
      }).then((response) => {
        postApiWithToken(response.accessToken, protectedResources.removeDL.endpoint, {
          "dl_PrimarySmtpAddress": props.dl.PrimarySmtpAddress, 
          "user_PrimarySmtpAddress": response.account.username,
          "user_DisplayName": response.account.name
        }).then(() => {
          props.updateSpecificDLCallback(props.dl.PrimarySmtpAddress);
        });

      }).catch(async (e) => {
        if (e instanceof InteractionRequiredAuthError) {
          await instance.acquireTokenPopup({
            scopes: protectedResources.removeDL.scopes,
            account: account
          });

          props.markDLasLoadingCallback(props.dl.PrimarySmtpAddress, true)
          
        }

        throw e;
      })
    }


    return (
      <>
        <Button variant="danger" onClick={toggleModal} size="sm">
          Radera
        </Button>
  
        <Modal show={show} onHide={toggleModal} size="xl">
          <Modal.Header closeButton>
            <Modal.Title>Ta bort distributionslista</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <div class="row">
                  <div class="col">
                      <p>Om distributionslistan inte används längre, tryck på knappen Radera nedan för att markera den för borttagning.</p>
                  </div>
                  <div class="col">
                      <strong>Namn:</strong><br /> {props.dl.DisplayName}<br />
                  </div>
              </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={toggleModal}>
              Stäng
            </Button>
            <Button variant="danger" onClick={handleRemove}>
              Radera
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
}