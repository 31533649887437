import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';

import { protectedResources } from "../../authConfig";

import { postApiWithToken } from "../../fetch";
import { useAccount, useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-browser'

export function DLKeepModal (props) {

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [show, setShow] = useState(false);
    const [externalSenderCheckbox, setExternalSenderCheckbox] = useState(!props.dl.RequireSenderAuthenticationEnabled);

    function toggleModal() {
        setShow(!show);
    }

    const handleKeep = () => {
        props.markDLasLoadingCallback(props.dl.PrimarySmtpAddress);
        setShow(false);

        instance.acquireTokenSilent({
            scopes: protectedResources.keepDL.scopes,
            account: account
        }).then((response) => {
            console.log(response)
            postApiWithToken(response.accessToken, protectedResources.keepDL.endpoint, {
                "dl_PrimarySmtpAddress": props.dl.PrimarySmtpAddress, 
                "allowExternalSenders": externalSenderCheckbox,
                "user_PrimarySmtpAddress": response.account.username,
                "user_DisplayName": response.account.name})
            .then(() => {
                props.updateSpecificDLCallback(props.dl.PrimarySmtpAddress);
                
            });
    
        }).catch(async (e) => {
            if (e instanceof InteractionRequiredAuthError) {
              await instance.acquireTokenPopup({
                scopes: protectedResources.keepDL.scopes,
                account: account
              });
    
              props.markDLasLoadingCallback(props.dl.PrimarySmtpAddress, true)
              
            }
    
            throw e;
        })
    }

    function handleCheckboxChange(e) {
        setExternalSenderCheckbox(e.target.checked);
    }


    return (
        <>
            <Button variant="success" onClick={toggleModal} size="sm">
            Behåll
            </Button>
    
            <Modal show={show} onHide={toggleModal} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Behåll distributionslista</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div class="row">
                    <div class="col">
                        <p>Om distributionslistan fortfarande är aktiv och används, tryck på knappen Behåll nedan.</p>
                    </div>
                    <div class="col">
                        <strong>Namn:</strong><br /> {props.dl.DisplayName}<br />
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <div className='mb-3'>
                        {externalSenderCheckbox &&
                                <i class="bi bi-exclamation-triangle text-warning"></i>
                                }  <strong>Tillåt externa avsändare: </strong> 
                            <br />
                            <Form.Check 
                                type="switch"
                                id="custom-switch"
                                label="Distributionslistor innehåller ofta många användare och är därför sårbara för att utnyttjas i nätfiske- och spamattacker. Tillåt därför endast att externa
                                avsändare skickar mail till listan när det faktiskt behövs och verkligen används."
                                checked={externalSenderCheckbox}
                                onChange={handleCheckboxChange}
                            />
                        </div>
                    </div>
                    <div className='col'>

                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={toggleModal}>
                Stäng
                </Button>
                <Button variant="success" onClick={handleKeep}>
                Behåll
                </Button>
            </Modal.Footer>
            </Modal>
        </>
    );
}