import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function DLLiTooltip(props) {

    return <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="button-tooltip-2"><div dangerouslySetInnerHTML={{ __html: props.explanation}} /></Tooltip>}
    >
        {({ ref, ...triggerHandler }) => (
        <li
        {...triggerHandler}>
            {props.title} <i
            class="bi bi-info-circle"
            ref={ref}
            />
             
        </li>
        )}
    </OverlayTrigger>;
}

